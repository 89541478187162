<template>
  <div class="table-item content-item">
    <div class="table-item__field">
      {{ content.title }}
    </div>
    <div class="table-item__field">
      {{ content.date | dateFormater }}
    </div>
    <div class="table-item__field">
      {{ content.pdfId }}
    </div>
    <div class="table-item__field">
      <img class="preview" :src="content.imageUrl">
    </div>
    <div class="table-item__field">
      {{ content.updatedBy }}
    </div>
    <div class="table-item__field table-item__action">
      <button
        class="button button__edit"
        @click="updateContent"
      >Edit</button>
      <button
        class="button button__delete"
        @click="deleteContent"
      >Delete</button>
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/filters'
export default {
  name: 'ContentItem',
  filters: {
    dateFormater
  },
  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    updateContent () {
      this.$emit('update', this.content)
    },
    deleteContent () {
      this.$emit('delete', this.content)
    }
  }
}
</script>

<style scoped>

</style>
